<template>
  <el-menu
    class="sidebar"
    :default-active="defaultActive"
    mode="vertical"
    :router="true"
  >
    <el-menu-item index="/">
      <div class="sidebar-menu">下载订单</div>
    </el-menu-item>
    <el-menu-item index="/push_order">
      <div class="sidebar-menu">推单</div>
    </el-menu-item>
    <el-menu-item index="/log">
      <div class="sidebar-menu">操作日志</div>
    </el-menu-item>

    <!-- <el-submenu index="/setting">
      <template slot="title">
        <span>Setting</span>
      </template>
      <el-menu-item index="/category">category</el-menu-item>
      <el-menu-item index="/schoolType">School Type</el-menu-item>
      <el-menu-item index="/deptPlan">Dept./Plan</el-menu-item>
      <el-menu-item index="/eventNature">Event Nature</el-menu-item>
    </el-submenu> -->
  </el-menu>
</template>

<script>

export default {
  data() {
    return {
      roles: "value",
    };
  },

  computed: {
    defaultActive() {
      const path = this.$route.path;
      const re = /^(\/[^/]*)/g;
      return re.exec(path)[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  min-height: 100vh;
  height: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

.is-active {
  background-color: #f7f7f7;
  color: #242527;
  font-size: 14px !important;
}

.el-menu-item {
  color: #666872;
}
.sidebar-menu-line {
  margin-left: 30px;
  border-bottom: 1px solid #e1e4ef;
}
.sidebar-menu {
  padding-left: 30px;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>