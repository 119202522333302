<template>
  <el-container class="layout">
    <el-container>
      <transition name="fade-transform" mode="out-in">
        <Menu v-show="sidebar.opened"></Menu>
      </transition>
      <el-main class="main">
        <el-card class="header-container">
          <!-- <hamburger
            class="hamburger-container"
            :is-active="sidebar.opened"
            @toggleClick="toggleSideBar"
          /> -->
          <span
            style="
              float: right;
              margin-right: 20px;
              line-height: 10px;
              cursor: pointer;
            "
            @click="logout"
            >登出</span
          >
        </el-card>
        <router-view :key="key" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import { logout } from "@/api/security";
import { removeToken, removeUserInfo, removeRefreshToken } from "@/utils/auth";
import Menu from "./Menu";
export default {
  components: { Menu },
  computed: {
    key() {
      return this.$route.path;
    },
    ...mapGetters(["sidebar"]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    logout() {
      logout().then(() => {
        removeToken();
        removeUserInfo();
        removeRefreshToken();
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
}
@keyframes fade-transform {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.main {
  background-color: #f2f3f5;
}

.el-main {
  padding: 0px;
}

.header-container {
  height: 48px;
  width: 100%;
  margin-bottom: 5px;
  padding-top: 20px;
  padding-left: 20px;
  .hamburger-container {
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    bottom: 3px;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
